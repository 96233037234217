import React, { useState, useEffect, ChangeEvent, FunctionComponent } from 'react'
import {Layout, Row, Space, Col, Menu, message, Modal, notification, Typography, Card, AutoComplete, List, Input, Table, Select, Divider, InputNumber, Breadcrumb} from 'antd'
import {Role} from 'auth0'
import {Link, useParams} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import debounce from 'debounce'

import Button from 'antd-button-color'
import 'antd-button-color/dist/css/style.css'

import {PlusSquareOutlined, EditOutlined, UpOutlined, DownOutlined, CloseOutlined, ImportOutlined, ExportOutlined} from '@ant-design/icons'
import ordinal from 'ordinal'
import merge from 'deepmerge'

//import {Breadcrumb} from '../Breadcrumb/Breadcrumb'
import {useAuthContext} from '../auth/AuthProvider'
import {IIntegration, ILayout, IOrganization, IAssociatedSections} from '../types'
import {SPONSORED_PRODUCTS_BASE_URL as APP_BASE_URL} from '../constants'
import {ColorInput} from '../Forms/ColorInput'
import { LoadingScreen } from '../components/Loader/Loader'
import { hasAuth0Access, Auth0Roles } from '../auth/Auth0Bridge'

type Integration = IIntegration & {associated_layouts: ILayout[]; associated_organization: IOrganization}

interface DesignProps {
  integration: IIntegration,
}

const ModalSection: React.FC<{title: string}> = (props) => {
  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Typography.Title level={4}>{props.title}</Typography.Title>
        </Col>
      </Row>
      <Divider />
      {
        props.children
      }
    </>
  )
}

export const Design: FunctionComponent<DesignProps> = (props) => {
  const {user, authenticatedFetch} = useAuthContext()
  const [integration, setIntegration] = useState<Integration | null>(null)
  const [isIntegrationLoading, setIsIntegrationLoading] = useState(false)
  const [isEnabled, setIsEnabled] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showEditPlacement, setShowEditPlacement] = useState(false)
  const [showEditSection, setShowEditSection] = useState(false)
  const [selectedLayout, setSelectedLayout] = useState<ILayout | null>(null)
  const [selectedSection, setSelectedSection] = useState<{section: IAssociatedSections | null; index: number}>({index: 0, section: null})
  const [newRoute, setNewRoute] = useState<Partial<{route: string; active: number}>>({})
  const [userOrganizationRoles, setUserOrganizationRoles] = useState<Role[]>([])

  const {
    errors,
    handleSubmit,
    control,
    setValue,
    reset,
  } = useForm({shouldUnregister: false})

  const onEditLayoutSubmit = debounce(async (values: Partial<ILayout>) => {
    setIsSubmitting(true)
    try {
      await authenticatedFetch<Integration>(
        APP_BASE_URL+`api/layout/${values._id}`,
        'PATCH',
        values,
      )
      const response = await authenticatedFetch<Integration>(
        APP_BASE_URL+`api/integration/${props.integration?._id}?populate=associated_organization&populate=associated_layouts`,
      )
      setIsEnabled(Boolean(response.data.active))
      setIntegration(response.data)
      notification.success({
        message: 'Success',
        description: 'Layout data was saved successfully',
      })
    } catch (err) {
      notification.error({
        message:' Error',
        description: `Layout data failed to save with error: ${err.message}`
      })
    }
    setShowEditPlacement(false)
    setSelectedLayout(null)
    setNewRoute({})
    setIsSubmitting(false)
  }, 250) as any

  const onEditSectionSubmit = debounce(async (layout: ILayout, section: IAssociatedSections, index: number) => {
    setIsSubmitting(true)
    try {
      const update = merge({}, layout)
      update.associated_sections[index] = section
      await authenticatedFetch<Integration>(
        APP_BASE_URL+`api/layout/${layout._id}`,
        'PATCH',
        update,
      )
      const response = await authenticatedFetch<Integration>(
        APP_BASE_URL+`api/integration/${props.integration?._id}?populate=associated_organization&populate=associated_layouts`,
      )
      setIsEnabled(Boolean(response.data.active))
      setIntegration(response.data)
      notification.success({
        message: 'Success',
        description: 'Layout data was saved successfully',
      })
    } catch (err) {
      notification.error({
        message:' Error',
        description: `Layout data failed to save with error: ${err.message}`
      })
    }
    setShowEditSection(false)
    setSelectedLayout(null)
    setSelectedSection({section: null, index: 0})
    setIsSubmitting(false)
  }, 250) as any

  useEffect(() => {

    setIsIntegrationLoading(true)
    ;(async () => {
      try {
        const response = await authenticatedFetch<Integration>(
          APP_BASE_URL+`api/integration/${props.integration._id}?populate=associated_organization&populate=associated_layouts`,
        )
        setIsEnabled(Boolean(response.data.active))
        setIntegration(response.data)

        const userRolesResponse = await authenticatedFetch<Role[]>(
          APP_BASE_URL + `portal/user/organizations/${response.data.associated_organization._id}/roles`,
        )

        setUserOrganizationRoles(userRolesResponse.data)

      } catch (err) {
        message.error(`failed to load organization: ${err.message}`)
      }
      setIsIntegrationLoading(false)
    })()
  }, [props.integration])

  return isIntegrationLoading ? (
    <LoadingScreen /> 
  ) : (
    <Layout.Content style={{width: '100%'}}>
      {/* <Divider orientation="left">
        Design Settings
      </Divider> */}

      <Typography.Title level={3}>Design Settings</Typography.Title>
      <Card>
      <Row style={{width: '100%'}}>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
          <Space direction="vertical" size="middle">
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Button type="success" ghost icon={<PlusSquareOutlined />} size="small" style={{width: '100%', alignContent: 'start'}} disabled={ !hasAuth0Access(userOrganizationRoles, Auth0Roles.Editor) }>
                      New Placement
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Button type="success" ghost icon={<PlusSquareOutlined />} size="small" style={{width: '100%'}} disabled={ !hasAuth0Access(userOrganizationRoles, Auth0Roles.Editor) }>
                      New Layout
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Typography.Paragraph strong>Edit Layouts</Typography.Paragraph>
                  </Col>
                </Row>
                {
                  integration && integration.associated_layouts
                    ? integration.associated_layouts.map(layout => {
                      return (
                        <>
                          <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                              <Button
                                type="primary"
                                ghost
                                icon={<EditOutlined />}
                                size="small"
                                onClick={() => {
                                  setSelectedLayout(layout)
                                  setShowEditPlacement(true)
                                }}
                              >
                                {layout.title}
                              </Button>
                            </Col>
                          </Row>
                          {
                            layout.associated_sections
                              ? (
                                layout.associated_sections.map((section, index) => {
                                  return (
                                    <Row>
                                      <Col span={2} />
                                      <Col xs={14} sm={14} md={14} lg={14} xl={14} xxl={14}>
                                        <Button
                                          type="dark"
                                          ghost
                                          icon={<EditOutlined />}
                                          size="small"
                                          onClick={() => {
                                            setSelectedLayout(layout)
                                            setSelectedSection({index, section})
                                            setShowEditSection(true)
                                          }}
                                        >
                                          {`${index+1}. ${section.layout_section_template_options.section_title}`}
                                        </Button>
                                      </Col>
                                      <Col span={8} />
                                    </Row>
                                  )
                                })

                              )
                              : null
                          }
                        </>
                      )
                    })
                    : null
                }
              </Col>
            </Row>
          </Space>
        </Col>
        <Col span={1} />
        <Col xs={17} sm={17} md={17} lg={17} xl={17} xxl={17}>
          <Space direction="vertical" size="large" style={{width: '100%'}}>
            <Card
              title={
                <Space direction="vertical" size="middle" style={{width: '100%'}}>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Typography.Title level={4}>Layouts</Typography.Title>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <AutoComplete
                        options={
                          integration ? integration.associated_layouts.map(layout => ({value: layout._id, label: layout.title})) : []
                        }
                        placeholder='Search'
                        style={{width: '100%'}}
                      />
                    </Col>
                  </Row>
                </Space>
              }
            >
              <List
                pagination={{
                  pageSize: 10,
                }}
                dataSource={integration ? integration.associated_layouts.map(layout => layout.title) : []}
                renderItem={(item) => {
                  return (
                    <List.Item>{item}</List.Item>
                  )
                }}
              />
            </Card>
          </Space>
        </Col>
      </Row>
      </Card>
      <Modal
        visible={showEditPlacement}
        onCancel={() => {
          setShowEditPlacement(false)
          setSelectedLayout(null)
          setNewRoute({})
        }}
        onOk={() => {
          onEditLayoutSubmit(selectedLayout)
        }}
        okText="Save"
        title={selectedLayout ? `Edit ${selectedLayout.title}` : ''}
        width={1000}
        footer={[
          <Button 
            type="danger"
            disabled={ !hasAuth0Access(userOrganizationRoles, Auth0Roles.Editor) }
            >Delete</Button>,
          <Button 
            type="primary" 
            onClick={() => onEditLayoutSubmit(selectedLayout)}
            disabled={ !hasAuth0Access(userOrganizationRoles, Auth0Roles.Editor) }
          >Save</Button>
        ]}
      >
        <Space direction="vertical" style={{width: '100%'}} size="large">
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Typography.Title level={4}>Page Type URL Structure</Typography.Title>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <span>Add the URL structure of your layout.</span>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Input
                defaultValue={selectedLayout ? selectedLayout.title : undefined}
                onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                  setSelectedLayout(
                    Object.assign({}, selectedLayout, {title: ev.target.value})
                  )
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Table
                columns={[
                  {
                    title: 'Active',
                    dataIndex: 'active',
                    render: (value: string | number, record: any, index: number) => {
                     return (
                       <Select
                        style={{width: '100%'}}
                        placeholder="Please Select"
                        defaultValue={value}
                        onChange={(value: any) => {
                          const update = Object.assign({}, record, {active: value})
                          const layout = merge({}, selectedLayout || {})
                          layout.routes[index] = update
                          setSelectedLayout(layout)
                        }}
                       >
                        <Select.Option value={0}>False</Select.Option>
                        <Select.Option value={1}>True</Select.Option>
                       </Select>
                     )
                    }
                  },
                  {
                    title: 'Route',
                    dataIndex: 'route',
                  },
                  {
                    dataIndex: 'route',
                    render: (value: string, record: any, index: number) => {
                      return (
                        <Row justify="end">
                          <Col>
                            {
                              selectedLayout && selectedLayout.routes && selectedLayout.routes.length - 1 !== index
                                ? (
                                  <Button
                                    icon={<DownOutlined />}
                                    onClick={() => {
                                      const routes: any[] = merge([], selectedLayout.routes || [])
                                      const currentIndex = routes[index]
                                      const newIndex = routes[index+1]
                                      routes[index] = newIndex
                                      routes[index+1] = currentIndex
                                      setSelectedLayout(
                                        Object.assign({}, selectedLayout, {routes})
                                      )
                                    }}
                                  />
                                )
                                : null
                            }
                            {
                              index !== 0
                                ? (
                                  <Button
                                    icon={<UpOutlined />}
                                    onClick={() => {
                                      const routes: any[] = merge([], selectedLayout && selectedLayout.routes ? selectedLayout.routes : [])
                                      const currentIndex = routes[index]
                                      const newIndex = routes[index-1]
                                      routes[index] = newIndex
                                      routes[index-1] = currentIndex
                                      setSelectedLayout(
                                        Object.assign({}, selectedLayout, {routes})
                                      )
                                    }}
                                  />
                                )
                                : null
                            }
                            <Button
                              icon={<CloseOutlined />}
                              onClick={() => {
                                const routes: any[] = merge([], selectedLayout && selectedLayout.routes ? selectedLayout.routes : [])
                                routes.splice(index, 1)
                                setSelectedLayout(
                                  Object.assign({}, selectedLayout, {routes})
                                )
                              }}
                            />
                          </Col>
                        </Row>
                      )
                    },
                  }
                ]}
                dataSource={selectedLayout ? selectedLayout.routes : []}
                pagination={false}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Row>
                <Col xs={7} sm={7} md={7} lg={7} xl={7} xxl={7}>
                  <Select
                    style={{width: '100%'}}
                    placeholder="Please Select"
                    value={newRoute.active}
                    onChange={(value: any) => {
                      setNewRoute(
                        Object.assign({}, newRoute, {active: value})
                      )
                    }}
                  >
                    <Select.Option value={0}>False</Select.Option>
                    <Select.Option value={1}>True</Select.Option>
                  </Select>
                </Col>
                <Col span={1} />
                <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                  <Input
                    style={{width: '100%'}}
                    placeholder="Route"
                    value={newRoute.route}
                    onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                      setNewRoute(
                        Object.assign({}, newRoute, {route: ev.target.value})
                      )
                    }}
                  />
                </Col>
                <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                  <Row justify="end">
                    <Col>
                      <Button
                        type="primary"
                        icon={<PlusSquareOutlined />}
                        onClick={() => {
                          const routes: any[] = merge([], selectedLayout && selectedLayout.routes ? selectedLayout.routes : [])
                          routes.push(newRoute)
                          setNewRoute({})
                          setSelectedLayout(
                            Object.assign({}, selectedLayout, {routes})
                          )
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Typography.Title level={4}>Placements</Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Table
                columns={[
                  {
                    title: 'Active',
                    dataIndex: 'active',
                    render: (value: string | number, record: any, index: number) => {
                      return (
                        <Select
                         style={{width: '100%'}}
                         placeholder="Please Select"
                         defaultValue={value}
                         onChange={(value: any) => {
                          const update = Object.assign({}, record, {active: value})
                          const layout = merge({}, selectedLayout || {})
                          layout.associated_sections[index] = update
                          setSelectedLayout(layout)
                        }}
                        >
                         <Select.Option value={0}>False</Select.Option>
                         <Select.Option value={1}>True</Select.Option>
                        </Select>
                      )
                     }
                  },
                  {
                    title: 'Placement',
                    render: () => 'Carousel',
                  },
                  {
                    title: 'Title',
                    dataIndex: 'layout_section_template_options.section_title',
                    render: (value: string, record: any, index: number) => {
                      return (
                        <Input
                          style={{width: '100%'}}
                          defaultValue={record.layout_section_template_options.section_title}
                          onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                            const section: any = merge({}, record)
                            section.layout_section_template_options.section_title = ev.target.value
                            const layout = merge({}, selectedLayout || {})
                            layout.associated_sections[index] = section
                            setSelectedLayout(layout)
                          }}
                        />
                      )
                    }
                  },
                  {
                    title: 'Advanced Selector',
                    dataIndex: 'exact_target_selector',
                    render: (value: string, record: any, index: number) => {
                      return (
                        <Input
                          style={{width: '100%'}}
                          defaultValue={value}
                          onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                            const section: any = merge({}, record)
                            section.exact_target_selector = ev.target.value
                            const layout = merge({}, selectedLayout || {})
                            layout.associated_sections[index] = section
                            setSelectedLayout(layout)
                          }}
                        />
                      )
                    }
                  },
                  {
                    title: 'Position',
                    dataIndex: 'position',
                    render: (value: string, record: any, index: number) => {
                      return (
                        <Select
                         style={{width: '100%'}}
                         placeholder="Please Select"
                         defaultValue={value}
                         onChange={(value: any) => {
                          const update = Object.assign({}, record, {position: value})
                          const layout = merge({}, selectedLayout || {})
                          layout.associated_sections[index] = update
                          setSelectedLayout(layout)
                        }}
                        >
                         <Select.Option value="before">Place above element</Select.Option>
                         <Select.Option value="after">Place below element</Select.Option>
                         <Select.Option value="replace">Replace element</Select.Option>
                         <Select.Option value="insert">Place inside top</Select.Option>
                         <Select.Option value="append">Place inside bottom</Select.Option>
                        </Select>
                      )
                    }
                  },
                  {
                    align: 'center',
                    render: (value: any, record: any, index: number) => {
                      return (
                        <Button
                          icon={<CloseOutlined />}
                          onClick={() => {
                            const layout = merge({}, selectedLayout || {})
                            layout.associated_sections.splice(index, 1)
                            setSelectedLayout(layout)
                          }}
                        />
                      )
                    }
                  }
                ]}
                dataSource={selectedLayout ? selectedLayout.associated_sections : []}
              />
            </Col>
          </Row>
        </Space>
      </Modal>
      <Modal
        visible={showEditSection}
        onCancel={() => {
          setShowEditSection(false)
          setSelectedLayout(null)
          setSelectedSection({section: null, index: 0})
        }}
        onOk={() => {
          onEditSectionSubmit(selectedLayout, selectedSection.section, selectedSection.index)
        }}
        okText="Update Placement"
        okButtonProps={ { disabled :  !hasAuth0Access(userOrganizationRoles, Auth0Roles.Editor) }}
        title={selectedSection && selectedSection.section ? `Edit ${selectedSection.index+1}. ${selectedSection.section.layout_section_template_options.section_title}` : ''}
        width={1000}
      >
        <Card
          title={
            <Row>
              <Col xs={18} sm={18} md={18} lg={18} xl={18} xxl={18}>
                <Typography.Title level={4}>Editing {`${selectedLayout ? selectedLayout.title : ''} ${ordinal(selectedSection.index+1)} placement (carousel)`}</Typography.Title>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <Row justify="end">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Space direction="horizontal" size="small" style={{width: '100%'}}>
                      <Button
                        icon={<ImportOutlined />}
                      >
                        Import
                      </Button>
                      <Button
                        icon={<ExportOutlined />}
                      >
                        Export
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          }
        >
          <Space direction="vertical" size="large" style={{width: '100%'}}>
            <ModalSection title="Title">
              <Space direction="vertical" size="small" style={{width: '100%'}}>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Title</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input
                      defaultValue={selectedSection.section ? selectedSection.section.layout_section_template_options.section_title : ''}
                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.section_title = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Title Alignment</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Select
                      style={{width: '100%'}}
                      defaultValue={selectedSection.section ? (selectedSection.section.layout_section_template_options.section_title_text_align || 'left') : 'left'}
                      onChange={(value: any) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.section_title_text_align = value
                        setSelectedSection(section)
                      }}
                    >
                      <Select.Option value="left">Left</Select.Option>
                      <Select.Option value="center">Center</Select.Option>
                      <Select.Option value="right">Right</Select.Option>
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Title Position</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Select
                      style={{width: '100%'}}
                      defaultValue={selectedSection.section ? (selectedSection.section.layout_section_template_options.section_title_position || 'top') : 'top'}
                      onChange={(value: any) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.section_title_position = value
                        setSelectedSection(section)
                      }}
                    >
                      <Select.Option value="top">Top</Select.Option>
                      <Select.Option value="bottom">Bottom</Select.Option>
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Title Margin Top</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <InputNumber
                      defaultValue={selectedSection.section ? selectedSection.section.layout_section_template_options.title_margin_top : 0}
                      style={{width: '100%'}}
                      onChange={(value: number) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.title_margin_top = value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Title Margin Bottom</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <InputNumber
                      defaultValue={selectedSection.section ? selectedSection.section.layout_section_template_options.title_margin_bottom : 0}
                      style={{width: '100%'}}
                      onChange={(value: number) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.title_margin_bottom = value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
              </Space>
            </ModalSection>
            <ModalSection title="Placement">
              <Space direction="vertical" size="small" style={{width: '100%'}}>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Placement Margin Top</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <InputNumber
                      defaultValue={selectedSection.section ? selectedSection.section.layout_section_template_options.section_margin_top : 0}
                      style={{width: '100%'}}
                      onChange={(value: number) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.section_margin_top = value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Placement Margin Bottom</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <InputNumber
                      defaultValue={selectedSection.section ? selectedSection.section.layout_section_template_options.section_margin_bottom : 0}
                      style={{width: '100%'}}
                      onChange={(value: number) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.section_margin_bottom = value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Position</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Select
                      style={{width: '100%'}}
                      placeholder="Please Select"
                      defaultValue={selectedSection.section ? selectedSection.section.position : 0}
                      onChange={(value: any) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.position = value
                        setSelectedSection(section)
                      }}
                    >
                      <Select.Option value="before">Place above element</Select.Option>
                      <Select.Option value="after">Place below element</Select.Option>
                      <Select.Option value="replace">Replace element</Select.Option>
                      <Select.Option value="insert">Place inside top</Select.Option>
                      <Select.Option value="append">Place inside bottom</Select.Option>
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Advanced Selector</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input
                      defaultValue={selectedSection.section ? selectedSection.section.exact_target_selector: ''}
                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.exact_target_selector = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Number of Placements</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <InputNumber
                      defaultValue={selectedSection.section ? selectedSection.section.layout_section_template_options.number_of_catalog_items : 0}
                      style={{width: '100%'}}
                      onChange={(value: number) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.number_of_catalog_items = value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Minimum Placements to Render</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <InputNumber
                      defaultValue={selectedSection.section ? (selectedSection.section.layout_section_template_options.model_minimum_items || 3) : 3}
                      style={{width: '100%'}}
                      onChange={(value: number) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.model_minimum_items = value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Replicate Items to Fill Placements</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Select
                      style={{width: '100%'}}
                      placeholder="Please Select"
                      defaultValue={selectedSection.section ? (selectedSection.section.layout_section_template_options.replicate_items_carousel  || "1") : "1"}
                      onChange={(value: any) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.replicate_items_carousel = value
                        setSelectedSection(section)
                      }}
                    >
                      <Select.Option value="1">True</Select.Option>
                      <Select.Option value="0">False</Select.Option>
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Model</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Select
                      style={{width: '100%'}}
                      placeholder="Please Select"
                      defaultValue={selectedSection.section ? selectedSection.section.layout_section_template_options.model_name_selection : "1"}
                      onChange={(value: any) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.model_name_selection = value
                        setSelectedSection(section)
                      }}
                    >
                      <Select.Option value="similar-items">Similar Items</Select.Option>
                      <Select.Option value="recently-viewed-items">Recently Viewed Items</Select.Option>
                      <Select.Option value="explore-items">Explore Items</Select.Option>
                      <Select.Option value="deepfm-filtered-model">DeepFM - Similar Products (Filtered)</Select.Option>
                      <Select.Option value="deepfm-unfiltered-model">DeepFM - Similar Products (Unfiltered)</Select.Option>
                      <Select.Option value="deepfm-personalized-model">DeepFM - Personalized</Select.Option>
                      <Select.Option value="simple-embedding-items">Click Embedded 2D Model 4.0 (1589390520024)</Select.Option>
                      <Select.Option value="content-only-model">Deep Context Model 1.0 (1589390520024)</Select.Option>
                      <Select.Option value="content-enhanced-model">Deep Context Model 2.0 (1589390520024)</Select.Option>
                      <Select.Option value="content-filtered-model">Deep Context Model 3.0 (1589390520024)</Select.Option>
                      <Select.Option value="clicks-colabfilter-model">Matrix Factorization Model (1589390520024)</Select.Option>
                      <Select.Option value="full-colabfilter-model">Matrix Factorization Model 2.0 (1589390520024)</Select.Option>
                      <Select.Option value="personalized-colabfilter-model">Matrix Factorization Model 2.0 Personalized (1589390520024)</Select.Option>
                      <Select.Option value="fbt-colabfilter-model">Matrix Factorization Model 2.1 Frequently Bought Together (1589390520024)</Select.Option>
                      <Select.Option value="top-selling-model">Type-wise Top Selling Items (1589390520024)</Select.Option>
                      <Select.Option value="bpr-product-model">Bayesian Personalized Ranking Model (Similar Products) (1589390520024)</Select.Option>
                      <Select.Option value="bpr-personalized-model">Bayesian Personalized Ranking Model (Personalized) (1589390520024)</Select.Option>
                      <Select.Option value="revised-colabfilter-product-model">Matrix Factorization Model 2.1 - Revised Weights (1589390520024)</Select.Option>
                      <Select.Option value="revised-colabfilter-personalized-model">Matrix Factorization Model (Personalized) 2.1 - Revised Weights (1589390520024)</Select.Option>
                      <Select.Option value="department-bestseller-model">Department-wise Bestsellers</Select.Option>
                      <Select.Option value="bert-model">BERT Similar Product Recommender</Select.Option>
                      <Select.Option value="department-bestseller-long-model">Department-wise Bestsellers (Long)</Select.Option>
                      <Select.Option value="colabfilter-personalized-long-model">Matrix Factorization Model (Personalized) Long Logs</Select.Option>
                      <Select.Option value="colabfilter-product-long-model">Matrix Factorization Model (Long)</Select.Option>
                      <Select.Option value="top-selling-long-model">Type-wise Top Selling Items (Long Logs)</Select.Option>
                      <Select.Option value="fbt-colabfilter-long-model">Matrix Factorization Model 2.1 Frequently Bought Together (Long Logs)</Select.Option>
                      <Select.Option value="logisticmf-personalized-model">Logistic Matrix Factorization Model (Personalized)</Select.Option>
                      <Select.Option value="logisticmf-product-model">Logistic Matrix Factorization Model (Similar Product)</Select.Option>
                      <Select.Option value="integration-similar-items-production-madeira-madeira">Integration Similiar Items Production Madeira Madeira (1587695735192)</Select.Option>
                      <Select.Option value="integration-similar-items-furniture">Integration Similiar Items furniture (1587692657837)</Select.Option>
                      <Select.Option value="global-similar-items">Global Similiar Items (1587655320015)</Select.Option>
                      <Select.Option value="integration-similar-items-apparel">Integration Similiar Items apparel (1584614640057)</Select.Option>
                      <Select.Option value="integration-similar-items-jewelry">Integration Similiar Items jewelry (1569732356241)</Select.Option>
                      <Select.Option value="integration-similar-items-wedding">Integration Similiar Items wedding (1569548815372)</Select.Option>
                      <Select.Option value="amir-test-logisticmf-product-model">Amir-Test Logistic Matrix Factorization Model (Similar Product)</Select.Option>
                      <Select.Option value="amir-test-logisticmf-personalized-model">Amir-Test Logistic Matrix Factorization Model (Personalized)</Select.Option>
                      <Select.Option value="recently-viewed-items-local-storage">Recently Viewed Items (Local Storage)</Select.Option>
                      <Select.Option value="colcci_firstplacementtest">Colcci First Placement Test</Select.Option>
                      <Select.Option value="consider-too">KNN model (consider too)</Select.Option>
                      <Select.Option value="retail">Google retail (retail)</Select.Option>
                      <Select.Option value="retail-lmf">Google Retail(LMF)</Select.Option>
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Model: Always Show</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Select
                      style={{width: '100%'}}
                      placeholder="Please Select"
                      defaultValue={selectedSection.section ? selectedSection.section.layout_section_template_options.model_show_all : 1}
                      onChange={(value: any) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.model_show_all = value
                        setSelectedSection(section)
                      }}
                    >
                      <Select.Option value={1}>True</Select.Option>
                      <Select.Option value={0}>False</Select.Option>
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Model: Limit Scope</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Select
                      style={{width: '100%'}}
                      placeholder="Please Select"
                      defaultValue={selectedSection.section ? selectedSection.section.layout_section_template_options.model_limit_scope : 1}
                      onChange={(value: any) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.model_limit_scope = value
                        setSelectedSection(section)
                      }}
                    >
                      <Select.Option value={1}>True</Select.Option>
                      <Select.Option value={0}>False</Select.Option>
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Model: Limit Type</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Select
                      style={{width: '100%'}}
                      placeholder="Please Select"
                      defaultValue={selectedSection.section ? selectedSection.section.layout_section_template_options.model_limit_type : 1}
                      onChange={(value: any) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.model_limit_type = value
                        setSelectedSection(section)
                      }}
                    >
                      <Select.Option value={1}>True</Select.Option>
                      <Select.Option value={0}>False</Select.Option>
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Enabled</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Select
                      style={{width: '100%'}}
                      placeholder="Please Select"
                      defaultValue={selectedSection.section ? selectedSection.section.active : 1}
                      onChange={(value: any) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.active = value
                        setSelectedSection(section)
                      }}
                    >
                      <Select.Option value={1}>True</Select.Option>
                      <Select.Option value={0}>False</Select.Option>
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Use Placeholder</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Select
                      style={{width: '100%'}}
                      placeholder="Please Select"
                      defaultValue={selectedSection.section ? (selectedSection.section.layout_section_template_options.model_use_placeholder  || "1") : "1"}
                      onChange={(value: any) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.model_use_placeholder = value
                        setSelectedSection(section)
                      }}
                    >
                      <Select.Option value="1">True</Select.Option>
                      <Select.Option value="0">False</Select.Option>
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Shuffle Catalog Items</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Select
                      style={{width: '100%'}}
                      placeholder="Please Select"
                      defaultValue={selectedSection.section ? (selectedSection.section.layout_section_template_options.shuffle_catalog_items  || "1") : "1"}
                      onChange={(value: any) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.shuffle_catalog_items = value
                        setSelectedSection(section)
                      }}
                    >
                      <Select.Option value="1">True</Select.Option>
                      <Select.Option value="0">False</Select.Option>
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Use Browser Number Format</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Select
                      style={{width: '100%'}}
                      placeholder="Please Select"
                      defaultValue={selectedSection.section ? selectedSection.section.layout_section_template_options.use_browser_number_format : 1}
                      onChange={(value: any) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.use_browser_number_format = value
                        setSelectedSection(section)
                      }}
                    >
                      <Select.Option value={1}>True</Select.Option>
                      <Select.Option value={0}>False</Select.Option>
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Minimum Number of Fraction Digits</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <InputNumber
                      defaultValue={selectedSection.section ? (selectedSection.section.layout_section_template_options.browser_number_format_min_fraction_digits || 2) : 2}
                      style={{width: '100%'}}
                      onChange={(value: number) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.browser_number_format_min_fraction_digits = value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
              </Space>
            </ModalSection>
            <ModalSection title="Carousel">
              <Space direction="vertical" size="small" style={{width: '100%'}}>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Auto Play Carousel</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Select
                      style={{width: '100%'}}
                      placeholder="Please Select"
                      defaultValue={selectedSection.section ? (selectedSection.section.layout_section_template_options.carousel_auto_play || 0) : 0}
                      onChange={(value: any) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_auto_play = value
                        setSelectedSection(section)
                      }}
                    >
                      <Select.Option value={1}>True</Select.Option>
                      <Select.Option value={0}>False</Select.Option>
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Mobile Carousel Items</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <InputNumber
                      defaultValue={selectedSection.section ? (selectedSection.section.layout_section_template_options.carousel_mobile_items || 2) : 2}
                      style={{width: '100%'}}
                      onChange={(value: number) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_mobile_items = value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Desktop Carousel Items</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <InputNumber
                      defaultValue={selectedSection.section ? (selectedSection.section.layout_section_template_options.carousel_desktop_items || 4) : 4}
                      style={{width: '100%'}}
                      onChange={(value: number) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_desktop_items = value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Carousel Padding Left</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <InputNumber
                      defaultValue={selectedSection.section ? (selectedSection.section.layout_section_template_options.carousel_stage_padding_left || 20) : 20}
                      style={{width: '100%'}}
                      onChange={(value: number) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_stage_padding_left = value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Carousel Padding Right</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <InputNumber
                      defaultValue={selectedSection.section ? (selectedSection.section.layout_section_template_options.carousel_padding_right || 20) : 20}
                      style={{width: '100%'}}
                      onChange={(value: number) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_padding_right = value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Hide Carousel Dots</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Select
                      style={{width: '100%'}}
                      placeholder="Please Select"
                      defaultValue={selectedSection.section ? (selectedSection.section.layout_section_template_options.carousel_dots_disabled || "1") : "1"}
                      onChange={(value: any) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_dots_disabled = value
                        setSelectedSection(section)
                      }}
                    >
                      <Select.Option value={"1"}>True</Select.Option>
                      <Select.Option value={"0"}>False</Select.Option>
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Image Padding Left</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <InputNumber
                      defaultValue={selectedSection.section ? (selectedSection.section.layout_section_template_options.carousel_image_padding_left || 20) : 20}
                      style={{width: '100%'}}
                      onChange={(value: number) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_image_padding_left = value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Image Padding Right</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <InputNumber
                      defaultValue={selectedSection.section ? (selectedSection.section.layout_section_template_options.carousel_image_padding_right || 20) : 20}
                      style={{width: '100%'}}
                      onChange={(value: number) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_image_padding_right = value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Hide Carousel Buttons</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Select
                      style={{width: '100%'}}
                      placeholder="Please Select"
                      defaultValue={selectedSection.section ? (selectedSection.section.layout_section_template_options.carousel_buttons_disabled || 0) : 0}
                      onChange={(value: any) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_buttons_disabled = value
                        setSelectedSection(section)
                      }}
                    >
                      <Select.Option value={1}>True</Select.Option>
                      <Select.Option value={0}>False</Select.Option>
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Carousel Dot: Active</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <ColorInput
                      color={selectedSection.section ? selectedSection.section.layout_section_template_options.carousel_dot_color_active : null}
                      onChange={(value: string) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_dot_color_active = value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Carousel Dot: Default</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <ColorInput
                      color={selectedSection.section ? selectedSection.section.layout_section_template_options.carousel_dot_color_default : null}
                      onChange={(value: string) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_dot_color_default = value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
              </Space>
            </ModalSection>
            <ModalSection title="Advanced: Title">
              <Space direction="vertical" size="small" style={{width: '100%'}}>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Title Style</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.section_title_style : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.section_title_style = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Title CSS</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.section_title_css : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.section_title_css = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>CSS Classes</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input
                      defaultValue={selectedSection.section ? selectedSection.section.layout_section_template_options.section_title_classnames : ''}
                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.section_title_classnames = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
              </Space>
            </ModalSection>
            <ModalSection title="Advanced: Placements">
              <Space direction="vertical" size="small" style={{width: '100%'}}>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Placement Style</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.section_style : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.section_style = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Placement CSS</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.section_style_css : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.section_style_css = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>CSS Classes</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input
                      defaultValue={selectedSection.section ? selectedSection.section.layout_section_template_options.section_placement_classnames : ''}
                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.section_placement_classnames = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Custom Script</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.custom_section_script : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.custom_section_script = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Custom Header JSONX</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.custom_header_jsonx : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.custom_header_jsonx = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Use Sale Price</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Select
                      style={{width: '100%'}}
                      placeholder="Please Select"
                      defaultValue={selectedSection.section ? (selectedSection.section.layout_section_template_options.use_sale_price || "0") : "0"}
                      onChange={(value: any) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.use_sale_price = value
                        setSelectedSection(section)
                      }}
                    >
                      <Select.Option value={"1"}>True</Select.Option>
                      <Select.Option value={"0"}>False</Select.Option>
                    </Select>
                  </Col>
                </Row>
              </Space>
            </ModalSection>
            <ModalSection title="Advanced: Carousel">
              <Space direction="vertical" size="small" style={{width: '100%'}}>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>CSS Classes</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input
                      defaultValue={selectedSection.section ? selectedSection.section.layout_section_template_options.section_image_wrapper_classnames : ''}
                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.section_image_wrapper_classnames = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Item Wrapper Style</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.item_wrapper_style : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.item_wrapper_style = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Image Wrapper Style</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.section_image_wrapper_style : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.section_image_wrapper_style = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Image Wrapper CSS</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.section_image_wrapper_css : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.section_image_wrapper_css = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Auto Play Duration (ms)</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <InputNumber
                      defaultValue={selectedSection.section ? selectedSection.section.layout_section_template_options.carousel_autoplay_duration : 3000}
                      style={{width: '100%'}}
                      onChange={(value: number) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_autoplay_duration = value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Image Style</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.carousel_image_style : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_image_style = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Image CSS</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.carousel_image_css : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_image_css = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Carousel Advanced Options</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.carousel_options : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_options = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Slide to Next Page on Click</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Select
                      style={{width: '100%'}}
                      placeholder="Please Select"
                      defaultValue={selectedSection.section ? (selectedSection.section.layout_section_template_options.carousel_slide_page || 0) : 0}
                      onChange={(value: any) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_slide_page = value
                        setSelectedSection(section)
                      }}
                    >
                      <Select.Option value={1}>True</Select.Option>
                      <Select.Option value={0}>False</Select.Option>
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Link Style</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.section_item_link_style : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.section_item_link_style = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Link CSS</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.section_item_link_css : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.section_item_link_css = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Custom CSS Media Queries</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.custom_css_media_queries : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.custom_css_media_queries = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Brand Style</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.section_item_brand_style : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.section_item_brand_style = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Price Style</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.section_item_price_style : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.section_item_price_style = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Price CSS</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.section_item_price_css : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.section_item_price_css = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Sale Price Style</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.section_item_sale_price_style : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.section_item_sale_price_style = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Sale Price CSS</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.section_item_sale_price_css : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.section_item_sale_price_css = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Price Format</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input
                      defaultValue={selectedSection.section ? selectedSection.section.layout_section_template_options.section_item_price_format : '$0,0.00'}
                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.section_item_price_format = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Custom Carousel Wrapper Props</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.carousel_catalog_item_jsonx_wrapper_props : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_catalog_item_jsonx_wrapper_props = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Custom Carousel JSX</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.carousel_catalog_item_jsonx : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_catalog_item_jsonx = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Use Left Arrow</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Select
                      style={{width: '100%'}}
                      placeholder="Please Select"
                      defaultValue={selectedSection.section ? (selectedSection.section.layout_section_template_options.carousel_left_arrow || "0") : "0"}
                      onChange={(value: any) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_left_arrow = value
                        setSelectedSection(section)
                      }}
                    >
                      <Select.Option value={"1"}>True</Select.Option>
                      <Select.Option value={"0"}>False</Select.Option>
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Use Right Arrow</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Select
                      style={{width: '100%'}}
                      placeholder="Please Select"
                      defaultValue={selectedSection.section ? (selectedSection.section.layout_section_template_options.carousel_right_arrow || "0") : "0"}
                      onChange={(value: any) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_right_arrow = value
                        setSelectedSection(section)
                      }}
                    >
                      <Select.Option value={"1"}>True</Select.Option>
                      <Select.Option value={"0"}>False</Select.Option>
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Left Arrow HTML</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.carousel_left_arrow_html : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_left_arrow_html = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Right Arrow HTML</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.carousel_right_arrow_html : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_right_arrow_html = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Custom Carousel Footer JSONX</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.custom_carousel_footer_jsonx : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.custom_carousel_footer_jsonx = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Custom Carousel Sale Price Suffix</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input
                      defaultValue={selectedSection.section ? selectedSection.section.layout_section_template_options.custom_carousel_sale_price_suffix : ''}
                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.custom_carousel_sale_price_suffix = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Custom Footer Script</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.custom_footer_script : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.custom_footer_script = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Custom Price Script</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.custom_price_script : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.custom_price_script = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Custom Sale Price Script</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.custom_sale_price_script : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.custom_sale_price_script = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Custom Pre-Render Script</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.custom_prerender_script : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.custom_prerender_script = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Wrapper Div Style</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.carousel_wrapper_div_style : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_wrapper_div_style = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Arrow Button Style</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.carousel_a_arrow_button_style : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.carousel_a_arrow_button_style = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Left Arrow Carousel Wrapper Div Style</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.left_arrow_carousel_wrapper_div_style : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.left_arrow_carousel_wrapper_div_style = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Right Arrow Carousel Wrapper Div Style</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input.TextArea
                      rows={5}
                      value={selectedSection.section ? selectedSection.section.layout_section_template_options.right_arrow_carousel_wrapper_div_style : ''}
                      onChange={(ev: ChangeEvent<HTMLTextAreaElement>) => {
                        const section: any = merge({}, selectedSection || {})
                        section.section.layout_section_template_options.right_arrow_carousel_wrapper_div_style = ev.target.value
                        setSelectedSection(section)
                      }}
                    />
                  </Col>
                </Row>
              </Space>
            </ModalSection>
          </Space>
        </Card>
      </Modal>
    </Layout.Content>
  )
}
