import React, { FunctionComponent, useEffect, useState } from 'react';
import './index.css';
import { Card, Col, notification, Row, Switch, Typography } from 'antd';
import { IAssociatedSections, IIntegration, ILayout, IOrganization } from '../../types';
import { debounce } from 'debounce';
import merge from 'deepmerge'
import { SPONSORED_PRODUCTS_BASE_URL } from '../../constants';
import {Role} from 'auth0'
import { useAuthContext } from '../../auth/AuthProvider';
import {hasAuth0Access, Auth0Roles} from '../../auth/Auth0Bridge'

interface CarroselItemProps {

  layout: ILayout
  associatedSections: IAssociatedSections
  index: number
  userRoles: Role[]

}

type Integration = IIntegration & { associated_layouts: ILayout[]; associated_organization: IOrganization }

export const CarroselItem: FunctionComponent<CarroselItemProps> = (props) => {

  const { authenticatedFetch } = useAuthContext()

  const [isToggling, setIsToggling] = useState(false)
  const [isActive, setIsActive] = useState(Boolean(props.associatedSections.active))
  const [associatedSections, setAssociatedSections] = React.useState(props.associatedSections)
  const [layout, setLayout] = React.useState(props.layout)

  const onUpdateSection = debounce(async (layout: ILayout, section: IAssociatedSections, index: number) => {

    setIsToggling(true)
    // v = 1 -v
    // .active! ^= 1


    //setIsSubmitting(true)

    try {

      const update = merge({}, layout)
      section.active = 1 - section.active!
      update.associated_sections[index] = section

      console.log(SPONSORED_PRODUCTS_BASE_URL + `api/layout/${layout._id}`)

      await authenticatedFetch<Integration>(
        SPONSORED_PRODUCTS_BASE_URL + `api/layout/${layout._id}`,
        'PATCH',
        update,
      )

      setLayout(update)
      setAssociatedSections(section)
      setIsActive(Boolean(section.active))

      notification.success({
        message: 'Success',
        description: 'Layout data was saved successfully',
      })

    } catch (err) {

      notification.error({
        message: ' Error',
        description: `Layout data failed to save with error: ${err.message}`
      })

    }

    setIsToggling(false)
  }, 250) as any

  useEffect(() => {

  }, [])

  return (
    <Card style={{ width: "100%", borderRadius: 2, margin: 2, padding: 8 }} key={associatedSections.section}>
      <Col>
        <Row style={{ verticalAlign: 'middle', display: 'flex', justifyContent: 'space-between' }} key={associatedSections.section}>
          <Typography.Text style={{ fontSize: 18 }}>
            {associatedSections.layout_section_template_options.section_title}
          </Typography.Text>
          <Switch
            loading={isToggling}
            checked={isActive}
            onChange={() => onUpdateSection(layout, associatedSections, props.index)}
            disabled={!hasAuth0Access(props.userRoles, Auth0Roles.Editor)}
          />
        </Row>
      </Col>
    </Card>
  );
}

export default CarroselItem;
