import React, {useState, useEffect} from 'react';

import {SPONSORED_PRODUCTS_BASE_URL} from './constants'

import {AuthConfig} from './types'

import { LoadingScreen } from './components/Loader/Loader';
import {AuthenticatedRouter} from './auth/AuthenticatedRouter'
import {Layout} from './Layout'
import {fetch} from './utils/fetch'

function App() {
  const [authConfig, setAuthConfig] = useState<Partial<AuthConfig>>({})
  const {clientId, domain} = authConfig

  useEffect(() => {
    ;(async () => {
      try {
        const result = await fetch<AuthConfig>(SPONSORED_PRODUCTS_BASE_URL+'app-config')
        setAuthConfig(result.data)
      } catch (err) {
        console.error('failed to load app config', err)
      }
    })()
  }, [clientId, domain])

  if (!clientId || !domain) {
    return <LoadingScreen />
  } else {
    return (
      <AuthenticatedRouter config={authConfig as AuthConfig}>
        <Layout />
      </AuthenticatedRouter>
    )
  }
}

export default App;
