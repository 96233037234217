import React, { useEffect, useState, ChangeEvent } from 'react'
import { Layout, message, Row, Typography, Col, Switch, Space, Avatar, Table, AutoComplete, Modal, Input, Select, Card } from 'antd'
import Button from 'antd-button-color'
import { Role } from 'auth0'
import debounce from 'debounce'
import './index.css'
import 'antd-button-color/dist/css/style.css'

import { ICatalogItem, IIntegration, IOrganization } from '../../types'
import { useAuthContext } from '../../auth/AuthProvider'

import { SPONSORED_PRODUCTS_BASE_URL } from '../../constants'
import { Loading, LoadingScreen, LoadingSize } from '../../components/Loader/Loader'

import { hasAuth0Access, Auth0Roles } from '../../auth/Auth0Bridge'

type Integration = IIntegration & { associated_organization: IOrganization }

export const CatalogItens: React.FC<{ integration: IIntegration }> = (props) => {
  const DEFAULT_PAGINATION = { limit: 10, offset: 0 }

  const { user, authenticatedFetch } = useAuthContext()
  const [catalogItems, setCatalogItems] = useState<ICatalogItem[]>([])
  const [integration, setIntegration] = useState<Integration | null>(null)
  const [isCatalogItemsLoading, setIsCatalogItemsLoading] = useState(false)
  const [catalogItemSearchPagination, setCatalogItemSearchPagination] = useState<{ limit: number; offset: number }>(DEFAULT_PAGINATION)
  const [catalogItemsTotalCount, setCatalogItemsTotalCount] = useState(-1)
  const [catalogItemCurrentPage, setCatalogItemCurrentPage] = useState(1)
  const [autocompleteOptions, setAutocompleteOptions] = useState<ICatalogItem[]>([])
  const [isAutocompleteLoading, setIsAutocompleteLoading] = useState(false)
  const [selectedCatalogItem, setSelectedCatalogItem] = useState<string | null>(null)
  const [editingCatalogItem, setEditingCatalogItem] = useState<ICatalogItem | null>(null)
  const [creatingCatalogItem, setCreatingCatalogItem] = useState<Partial<ICatalogItem> | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [userOrganizationRoles, setUserOrganizationRoles] = useState<Role[]>([])

  const onSearch = debounce(async (value: string) => {
    setIsAutocompleteLoading(true)
    setAutocompleteOptions([])
    try {
      const catalogItemsResponse = await authenticatedFetch<ICatalogItem[]>(
        SPONSORED_PRODUCTS_BASE_URL + `api/catalogitem?limit=5&filter=associated_integration eq '${props.integration._id}'&like=title like ${value}`
      )
      setAutocompleteOptions(catalogItemsResponse.data)
    } catch (err) {}
    setIsAutocompleteLoading(false)
  }, 250)

  const onEdit = debounce(async () => {
    const id = (editingCatalogItem as any)._id
    try {
      await authenticatedFetch<ICatalogItem>(SPONSORED_PRODUCTS_BASE_URL + `api/catalogitem/${id}`, 'PATCH', editingCatalogItem)
      setEditingCatalogItem(null)
      message.success(`updated item ${id}`)
      setSelectedCatalogItem(null)
    } catch (err: any) {
      message.error(`failed to update item ${id} with error: ${err.message}`)
    }
  }, 250)

  const onCreate = debounce(async () => {
    try {
      const response = await authenticatedFetch<ICatalogItem>(SPONSORED_PRODUCTS_BASE_URL + `api/catalogitem`, 'POST', creatingCatalogItem)
      setCreatingCatalogItem(null)
      message.success(`created item ${response.data._id}`)
      setSelectedCatalogItem(null)
    } catch (err: any) {
      message.error(`failed to create item with error: ${err.message}`)
    }
  }, 250)

  const onDelete = debounce(async (id: string) => {
    try {
      await authenticatedFetch<ICatalogItem>(SPONSORED_PRODUCTS_BASE_URL + `api/catalogitem/${id}`, 'DELETE')
      message.success(`delete item ${id}`)
      setSelectedCatalogItem(null)
    } catch (err: any) {
      message.error(`failed to delete item ${id} with error: ${err.message}`)
    }
  }, 250)

  useEffect(() => {
    setLoading(true)
    setCatalogItemsTotalCount(-1)
    setSelectedCatalogItem(null)
    setCatalogItemSearchPagination(DEFAULT_PAGINATION)
    setCatalogItemCurrentPage(1)
  }, [props.integration])

  useEffect(() => {
    setIsCatalogItemsLoading(true)
    ;(async () => {
      try {
        const query = !selectedCatalogItem
          ? `api/catalogitem?limit=${catalogItemSearchPagination.limit}&skip=${catalogItemSearchPagination.offset}&filter=associated_integration eq '${props.integration._id}'`
          : `api/catalogitem?filter=associated_integration eq '${props.integration._id}' and _id eq '${selectedCatalogItem}'`
        const requestUrl = SPONSORED_PRODUCTS_BASE_URL + query
        const catalogItemsResponse = await authenticatedFetch<ICatalogItem[]>(requestUrl)
        if (Array.isArray(catalogItemsResponse.data)) {
          setCatalogItems(catalogItemsResponse.data)
        }
        if (selectedCatalogItem) {
          setCatalogItemsTotalCount(1)
        }
        if (catalogItemsTotalCount === -1) {
          const catalogItemsCount = await authenticatedFetch<{ count: number }>(SPONSORED_PRODUCTS_BASE_URL + `api/catalogitem/count?filter=associated_integration eq '${props.integration._id}'`)
          setCatalogItemsTotalCount(catalogItemsCount.data.count)
        }
        const integrationResponse = await authenticatedFetch<Integration>(SPONSORED_PRODUCTS_BASE_URL + `api/integration/${props.integration._id}?populate=associated_organization`)

        setIntegration(integrationResponse.data)

        const userRolesResponse = await authenticatedFetch<Role[]>(SPONSORED_PRODUCTS_BASE_URL + `portal/user/organizations/${integrationResponse.data.associated_organization._id}/roles`)

        setUserOrganizationRoles(userRolesResponse.data)
      } catch (err: any) {
        message.error(`failed to load catalog items: ${err.message}`)
      }
      setIsCatalogItemsLoading(false)
      setLoading(false)
    })()
  }, [catalogItemSearchPagination, catalogItemsTotalCount, selectedCatalogItem])

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <Layout.Content style={{ width: '100%' }}>
      <Typography.Title level={3}>Catalog Settings</Typography.Title>
      <Card>
        <Row>
          <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
            <Row>
              <Typography.Title level={4}>Catalog Items</Typography.Title>
            </Row>
          </Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
            <Row justify="end">
              <Space direction="horizontal">
                <Button type="ghost" style={{ width: '100%' }} disabled={!hasAuth0Access(userOrganizationRoles, Auth0Roles.Editor)}>
                  Upload Catalog Items
                </Button>
                <Button
                  type="primary"
                  style={{ width: '100%' }}
                  disabled={!hasAuth0Access(userOrganizationRoles, Auth0Roles.Editor)}
                  onClick={() => {
                    setCreatingCatalogItem({
                      standard_features: {},
                    })
                  }}
                >
                  Create Catalog Item
                </Button>
              </Space>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <AutoComplete
              notFoundContent={isAutocompleteLoading ? <Loading size={LoadingSize.small} /> : null}
              placeholder="Search"
              style={{ width: '100%' }}
              onSearch={onSearch}
              onSelect={(value: string, option: any) => {
                setSelectedCatalogItem(option.key)
              }}
              allowClear
              onClear={() => {
                if (selectedCatalogItem) {
                  setSelectedCatalogItem(null)
                  setCatalogItemsTotalCount(-1)
                }
              }}
            >
              {autocompleteOptions.map((item) => {
                return (
                  <AutoComplete.Option key={item._id} value={item.title}>
                    {item.title}
                  </AutoComplete.Option>
                )
              })}
            </AutoComplete>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              loading={isCatalogItemsLoading}
              columns={[
                {
                  title: 'ID',
                  dataIndex: 'raw_data',
                  key: 'Id',
                  render: (value: any) => {
                    return value.id || value.ProductId
                  },
                },
                {
                  title: 'Image',
                  dataIndex: 'raw_data',
                  key: 'ImageUrl',
                  render: (value: any) => {
                    return <Avatar size={64} shape="square" src={value.imageLink || value.ImageUrl} />
                  },
                },
                {
                  title: 'Item',
                  dataIndex: 'title',
                  key: 'title',
                },
                {
                  title: 'Import Activated',
                  dataIndex: 'active',
                  key: 'active',
                  render: (active: boolean) => {
                    return active ? 'True' : 'False'
                  },
                },
                {
                  title: 'Force Enable',
                  dataIndex: 'manually_enabled',
                  key: 'manually_enabled',
                  render: (enabled: boolean) => {
                    return enabled ? 'True' : 'False'
                  },
                },
                // why 2 of the same columns??
                // {
                //   title: 'Disable',
                //   dataIndex: 'manually_disabled',
                //   key: 'manually_disabled',
                //   render: (disabled: boolean) => {
                //     return disabled ? 'True' : 'False'
                //   }
                // },
                {
                  title: 'Inventory',
                  dataIndex: 'standard_features',
                  key: 'inventory_quantity',
                  render: (values: any) => {
                    if (typeof values.inventory_quantity !== 'number') {
                      return 0
                    }
                    return values.inventory_quantity
                  },
                },
                {
                  dataIndex: '_id',
                  render: (id: string, record: any) => {
                    return (
                      <Space direction="horizontal">
                        <Button
                          ghost
                          type="success"
                          style={{ width: '100%' }}
                          onClick={() => {
                            setEditingCatalogItem(record)
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          ghost
                          type="danger"
                          style={{ width: '100%' }}
                          disabled={!hasAuth0Access(userOrganizationRoles, Auth0Roles.Editor)}
                          onClick={() => {
                            onDelete(id)
                          }}
                        >
                          Delete
                        </Button>
                      </Space>
                    )
                  },
                },
              ]}
              dataSource={catalogItems}
              pagination={{
                current: catalogItemCurrentPage,
                onChange(page: number, pageSize: number) {
                  setCatalogItemCurrentPage(page)
                  setCatalogItemSearchPagination({
                    limit: pageSize,
                    offset: (page - 1) * pageSize,
                  })
                },
                total: catalogItemsTotalCount,
              }}
            />
          </Col>
          <Modal
            title="Edit Catalog Item"
            visible={editingCatalogItem !== null}
            onCancel={() => {
              setEditingCatalogItem(null)
            }}
            onOk={() => {
              onEdit()
            }}
            width={'800px'}
            okButtonProps={{ disabled: !hasAuth0Access(userOrganizationRoles, Auth0Roles.Editor) }}
            // cancelButtonProps={ { disabled : true }}
          >
            {editingCatalogItem === null ? null : (
              <Space direction="vertical" style={{ width: '100%' }}>
                <Row>
                  <Col xs={14} sm={14} md={14} lg={14} xl={14} xxl={14}>
                    <Space direction="vertical" size={'middle'} style={{ width: '100%' }}>
                      <Row>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                          <Typography.Text strong>ID</Typography.Text>
                        </Col>
                        <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                          <Input
                            style={{ width: '100%' }}
                            value={editingCatalogItem.standard_features.item_id}
                            disabled
                            onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                              const standardFeatures = Object.assign({}, editingCatalogItem.standard_features, {
                                item_id: ev.target.value,
                              })
                              setEditingCatalogItem(Object.assign({}, editingCatalogItem, { standard_features: standardFeatures }))
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                          <Typography.Text strong>Title</Typography.Text>
                        </Col>
                        <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                          <Input
                            style={{ width: '100%' }}
                            value={editingCatalogItem.title}
                            onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                              setEditingCatalogItem(Object.assign({}, editingCatalogItem, { title: ev.target.value }))
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                          <Typography.Text strong>Catalog Type</Typography.Text>
                        </Col>
                        <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                          <Select
                            style={{ width: '100%' }}
                            value={editingCatalogItem.catalog_type}
                            onChange={(value: string) => {
                              setEditingCatalogItem(Object.assign({}, editingCatalogItem, { catalog_type: value }))
                            }}
                          >
                            {['Product', 'Content', 'Hotel', 'Vehicle', 'Recipe'].map((t) => {
                              return <Select.Option value={t.toLowerCase()}>{t}</Select.Option>
                            })}
                          </Select>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                          <Typography.Text strong>Type</Typography.Text>
                        </Col>
                        <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                          <Input
                            style={{ width: '100%' }}
                            value={editingCatalogItem.standard_features.item_type}
                            onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                              const standardFeatures = Object.assign({}, editingCatalogItem.standard_features, {
                                item_type: ev.target.value,
                              })
                              setEditingCatalogItem(Object.assign({}, editingCatalogItem, { standard_features: standardFeatures }))
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                          <Typography.Text strong>Brand</Typography.Text>
                        </Col>
                        <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                          <Input
                            style={{ width: '100%' }}
                            value={editingCatalogItem.standard_features.brand}
                            onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                              const standardFeatures = Object.assign({}, editingCatalogItem.standard_features, {
                                brand: ev.target.value,
                              })
                              setEditingCatalogItem(Object.assign({}, editingCatalogItem, { standard_features: standardFeatures }))
                            }}
                          />
                        </Col>
                      </Row>
                    </Space>
                  </Col>
                  <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Avatar shape="square" size={256} src={editingCatalogItem.standard_features.image_url_src} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
                    <Typography.Text strong>URL</Typography.Text>
                  </Col>
                  <Col xs={19} sm={19} md={19} lg={19} xl={19} xxl={19}>
                    <Input
                      style={{ width: '100%' }}
                      value={editingCatalogItem.standard_features.item_url}
                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        const standardFeatures = Object.assign({}, editingCatalogItem.standard_features, {
                          item_url: ev.target.value,
                        })
                        setEditingCatalogItem(Object.assign({}, editingCatalogItem, { standard_features: standardFeatures }))
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
                    <Typography.Text strong>Image URL</Typography.Text>
                  </Col>
                  <Col xs={19} sm={19} md={19} lg={19} xl={19} xxl={19}>
                    <Input
                      style={{ width: '100%' }}
                      value={editingCatalogItem.standard_features.image_url_src}
                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        const standardFeatures = Object.assign({}, editingCatalogItem.standard_features, {
                          image_url_src: ev.target.value,
                        })
                        setEditingCatalogItem(Object.assign({}, editingCatalogItem, { standard_features: standardFeatures }))
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
                    <Typography.Text strong>Categories</Typography.Text>
                  </Col>
                  <Col xs={19} sm={19} md={19} lg={19} xl={19} xxl={19}>
                    <Input
                      style={{ width: '100%' }}
                      value={editingCatalogItem.standard_features.categories.map((category: any) => category.value).join(',')}
                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        const standardFeatures = Object.assign({}, editingCatalogItem.standard_features, {
                          categories: ev.target.value.split(',').map((value) => ({ label: value, value })),
                        })
                        setEditingCatalogItem(Object.assign({}, editingCatalogItem, { standard_features: standardFeatures }))
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
                    <Typography.Text strong>Tags</Typography.Text>
                  </Col>
                  <Col xs={19} sm={19} md={19} lg={19} xl={19} xxl={19}>
                    <Input
                      style={{ width: '100%' }}
                      value={editingCatalogItem.standard_features.tags.map((category: any) => category.value).join(',')}
                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        const standardFeatures = Object.assign({}, editingCatalogItem.standard_features, {
                          tags: ev.target.value.split(',').map((value) => ({ label: value, value })),
                        })
                        setEditingCatalogItem(Object.assign({}, editingCatalogItem, { standard_features: standardFeatures }))
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Typography.Text strong>Gender Preference</Typography.Text>
                </Row>
                <Row>
                  {['Primary', 'Secondary', 'Ternary'].map((v) => {
                    return (
                      <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                        <Space direction="vertical" style={{ width: '100%' }}>
                          <Row>
                            <Typography.Text>{v}</Typography.Text>
                          </Row>
                          <Row>
                            <Col xs={23} sm={23} md={23} lg={23} xl={23} xxl={23}>
                            <Select
                              style={{ width: '100%' }}
                              options={['Unisex', 'Female', 'Male'].map((gender: string) => ({
                                label: gender,
                                value: gender.toLowerCase(),
                              }))}
                              value={editingCatalogItem.standard_features[`gender_preference_${v.toLowerCase()}`]}
                              onChange={(value: string) => {
                                const standardFeatures = Object.assign({}, editingCatalogItem.standard_features, {
                                  [`gender_preference_${v.toLowerCase()}`]: value,
                                })
                                setEditingCatalogItem(Object.assign({}, editingCatalogItem, { standard_features: standardFeatures }))
                              }}
                            />
                            </Col>
                          </Row>
                        </Space>
                      </Col>
                    )
                  })}
                </Row>
                <Row>
                  {[
                    {
                      label: 'Active',
                      property: 'active',
                    },
                    {
                      label: 'Always Enable',
                      property: 'manually_enabled',
                    },
                    {
                      label: 'Always Disable',
                      property: 'manually_disabled',
                    },
                  ].map((option) => {
                    return (
                      <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                        <Row>
                          <Typography.Text>{option.label}</Typography.Text>
                        </Row>
                        <Row>
                          <Switch
                            checked={(editingCatalogItem as any)[option.property] === true}
                            onChange={(value: any) => {
                              setEditingCatalogItem(Object.assign({}, editingCatalogItem, { [option.property]: value }))
                            }}
                          />
                        </Row>
                      </Col>
                    )
                  })}
                </Row>
              </Space>
            )}
          </Modal>
          <Modal
            title="Create a New Catalog Item"
            visible={creatingCatalogItem !== null}
            onCancel={() => {
              setCreatingCatalogItem(null)
            }}
            onOk={() => {
              onCreate()
            }}
            width={750}
          >
            {creatingCatalogItem === null ? null : (
              <Space direction="vertical" style={{ width: '100%' }}>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>ID</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input
                      style={{ width: '100%' }}
                      value={creatingCatalogItem.standard_features.item_id}
                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        const standardFeatures = Object.assign({}, creatingCatalogItem.standard_features, {
                          item_id: ev.target.value,
                        })
                        setCreatingCatalogItem(Object.assign({}, creatingCatalogItem, { standard_features: standardFeatures }))
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Title</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input
                      style={{ width: '100%' }}
                      value={creatingCatalogItem.title}
                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        setCreatingCatalogItem(Object.assign({}, creatingCatalogItem, { title: ev.target.value }))
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>URL</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input
                      style={{ width: '100%' }}
                      value={creatingCatalogItem.standard_features.item_url}
                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        const standardFeatures = Object.assign({}, creatingCatalogItem.standard_features, {
                          item_url: ev.target.value,
                        })
                        setCreatingCatalogItem(Object.assign({}, creatingCatalogItem, { standard_features: standardFeatures }))
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Catalog Type</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Select
                      style={{ width: '100%' }}
                      value={creatingCatalogItem.catalog_type}
                      onChange={(value: string) => {
                        setCreatingCatalogItem(Object.assign({}, creatingCatalogItem, { catalog_type: value }))
                      }}
                    >
                      {['Product', 'Content', 'Hotel', 'Vehicle', 'Recipe'].map((t) => {
                        return <Select.Option value={t.toLowerCase()}>{t}</Select.Option>
                      })}
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Image URL</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input
                      style={{ width: '100%' }}
                      value={creatingCatalogItem.standard_features.image_url_src}
                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        const standardFeatures = Object.assign({}, creatingCatalogItem.standard_features, {
                          image_url_src: ev.target.value,
                        })
                        setCreatingCatalogItem(Object.assign({}, creatingCatalogItem, { standard_features: standardFeatures }))
                      }}
                    />
                  </Col>
                </Row>
                <Row justify="center">
                  <Avatar shape="square" size={128} src={creatingCatalogItem.standard_features.image_url_src} />
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Type</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input
                      style={{ width: '100%' }}
                      value={creatingCatalogItem.standard_features.item_type}
                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        const standardFeatures = Object.assign({}, creatingCatalogItem.standard_features, {
                          item_type: ev.target.value,
                        })
                        setCreatingCatalogItem(Object.assign({}, creatingCatalogItem, { standard_features: standardFeatures }))
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Brand</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input
                      style={{ width: '100%' }}
                      value={creatingCatalogItem.standard_features.brand}
                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        const standardFeatures = Object.assign({}, creatingCatalogItem.standard_features, {
                          brand: ev.target.value,
                        })
                        setCreatingCatalogItem(Object.assign({}, creatingCatalogItem, { standard_features: standardFeatures }))
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Categories</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input
                      style={{ width: '100%' }}
                      value={creatingCatalogItem.standard_features.categories ? creatingCatalogItem.standard_features.categories.map((category: any) => category.value).join(',') : ''}
                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        const standardFeatures = Object.assign({}, creatingCatalogItem.standard_features, {
                          categories: ev.target.value.split(',').map((value) => ({ label: value, value })),
                        })
                        setCreatingCatalogItem(Object.assign({}, creatingCatalogItem, { standard_features: standardFeatures }))
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Typography.Text strong>Tags</Typography.Text>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Input
                      style={{ width: '100%' }}
                      value={creatingCatalogItem.standard_features.tags ? creatingCatalogItem.standard_features.tags.map((category: any) => category.value).join(',') : ''}
                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        const standardFeatures = Object.assign({}, creatingCatalogItem.standard_features, {
                          tags: ev.target.value.split(',').map((value) => ({ label: value, value })),
                        })
                        setCreatingCatalogItem(Object.assign({}, creatingCatalogItem, { standard_features: standardFeatures }))
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Typography.Text strong>Gender Preference</Typography.Text>
                </Row>
                <Row>
                  {['Primary', 'Secondary', 'Tertiary'].map((v) => {
                    return (
                      <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                        <Space direction="vertical" style={{ width: '100%' }}>
                          <Row>
                            <Typography.Text>{v}</Typography.Text>
                          </Row>
                          <Row>
                            <Select
                              style={{ width: '100%' }}
                              options={['Unisex', 'Female', 'Male'].map((gender: string) => ({
                                label: gender,
                                value: gender.toLowerCase(),
                              }))}
                              value={creatingCatalogItem.standard_features[`gender_preference_${v.toLowerCase()}`]}
                              onChange={(value: string) => {
                                const standardFeatures = Object.assign({}, creatingCatalogItem.standard_features, {
                                  [`gender_preference_${v.toLowerCase()}`]: value,
                                })
                                setCreatingCatalogItem(Object.assign({}, creatingCatalogItem, { standard_features: standardFeatures }))
                              }}
                            />
                          </Row>
                        </Space>
                      </Col>
                    )
                  })}
                </Row>
                <Row>
                  {[
                    {
                      label: 'Active',
                      property: 'active',
                    },
                    {
                      label: 'Always Enable',
                      property: 'manually_enabled',
                    },
                    {
                      label: 'Always Disable',
                      property: 'manually_disabled',
                    },
                  ].map((option) => {
                    return (
                      <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                        <Row>
                          <Typography.Text>{option.label}</Typography.Text>
                        </Row>
                        <Row>
                          <Switch
                            checked={(creatingCatalogItem as any)[option.property] === true}
                            onChange={(value: any) => {
                              setCreatingCatalogItem(Object.assign({}, creatingCatalogItem, { [option.property]: value }))
                            }}
                          />
                        </Row>
                      </Col>
                    )
                  })}
                </Row>
              </Space>
            )}
          </Modal>
        </Row>
      </Card>
    </Layout.Content>
  )
}

export default CatalogItens
