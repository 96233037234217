import axios, {AxiosResponse, Method} from 'axios'

export interface Fetch {
  <T = any>(url: string, method?: Method, body?: any, headers?: any): Promise<AxiosResponse<T>>
}

const DefaultHeaders = {
  'Content-Type': 'application/json',
}

export const fetch: Fetch = async <T = any>(url: string, method?: Method, body?: any, headers?: any): Promise<AxiosResponse<T>> => {
  return axios(url, {
    method: method || 'get',
    data: body && method && method.toLowerCase() !== 'get' ? body : undefined,
    headers: {
      ...DefaultHeaders,
      ...(headers || {}),
    },
  })
}

export function makeAuthenticatedFetch(accessToken: string | null): Fetch {
  const DefaultAuthenticatedHeaders = {
    'Authorization': `Bearer ${accessToken}`,
  }
  return async <T = any>(url: string, method?: Method, body?: any, headers?: any): Promise<AxiosResponse<T>> => {
    if (!accessToken) {
      throw new Error('access token is not set')
    }
    return fetch<T>(url, method, body, {...DefaultAuthenticatedHeaders, ...(headers || {})})
  }
}

