import React from 'react';

import { Menu } from 'antd';
import { FundProjectionScreenOutlined, AreaChartOutlined, BarChartOutlined, BookOutlined, ControlOutlined, CustomerServiceOutlined, DesktopOutlined, GroupOutlined, LogoutOutlined, PieChartOutlined, TeamOutlined, RiseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { IIntegration, IOrganization } from "../../types";

interface SidebarProps {
  logout: () => void,
  isAuthenticated: boolean,
  integration?: IIntegration, // Add integration prop
}

export interface IMenuItem {
  name: string
  label?: string
  link?: string
  icon?: React.ReactNode
  render?: (name: string) => React.Component
  items?: IMenuItem[]
}

export interface IMenu {
  items: IMenuItem[]
  selected?: string[]
}

const Sidebar = (props: SidebarProps) => {

  function makeMenuItem(item: IMenuItem) {
    if (item.items && item.items.length) {
      return (
        <Menu.SubMenu title={item.label ? item.label : item.name} key={item.name} icon={item.icon}>
          {
            item.items.map(makeMenuItem)
          }
        </Menu.SubMenu>
      )
    }
    return item.link
      ? (
        <Menu.Item key={item.name} title={item.name} icon={item.icon}>
          <Link to={item.link}>
            {item.label ? item.label : item.name}
          </Link>
        </Menu.Item>
      )
      : (
        <Menu.Item key={item.name} title={item.name} icon>{item.render ? item.render(item.name) : item.name}</Menu.Item>
      )
  }

  const menuItems: IMenu = {
    selected: ['overview'],
    items: [
      {
        name: 'overview',
        label: 'Overview',
        link: '/',
        icon: <DesktopOutlined />
      },
      ...(props.integration && props.integration._id == '65fc5d6a9a541513bafa57cd' ? [
        {
          name: 'onlineSalesReport',
          label: 'Online sales',
          link: '/salesreport',
          icon: <FundProjectionScreenOutlined />
        }
      ] : []),
      {
        name: 'analytics',
        label: 'Analytics',
        icon: <PieChartOutlined />,
        items: [
          {
            name: 'analyticsAudience',
            label: 'Audience',
            link: 'analytics/audience',
            icon: <AreaChartOutlined />
          },
          {
            name: 'analyticsCarousel',
            label: 'Carousel',
            link: 'analytics/performance',
            icon: <BarChartOutlined />
          }
        ]
      },
      {
        name: 'carousel',
        label: 'Carousel',
        icon: <GroupOutlined />,
        items: [
          {
            name: 'carouselSettings',
            label: 'Settings',
            link: 'carousel/settings',
            icon: <ControlOutlined />
          },
          // {
          //   name: 'carouselDesign',
          //   label: 'Design',
          //   link: 'carousel/design',
          //   icon: <LayoutOutlined />
          // }
        ]
      },
      {
        name: 'catalog',
        label: 'Catalog',
        icon: <BookOutlined />,
        items: [
          {
            name: 'catalogSettings',
            label: 'Settings',
            link: '/catalog/settings',
            icon: <ControlOutlined />
          },
          {
            name: 'catalogBoostItems',
            label: 'Boost Items',
            link: '/catalog/BoostAndFilter',
            icon: <RiseOutlined />
          }
        ]
      },
      {
        name: 'user_management',
        label: 'User Management',
        link: '/organization/users',
        icon: <TeamOutlined />
      },
      {
        name: 'help',
        label: 'Help',
        link: '/help',
        icon: <CustomerServiceOutlined />
      }

    ]
  }

  return (
    <div>
      <Menu
        mode="inline"
        defaultOpenKeys={menuItems.items.map(item => item.name)}
        defaultSelectedKeys={menuItems.selected}
      >
        {
          menuItems.items.map(makeMenuItem)
        }
        <Menu.Item key="/signout" onClick={props.logout as any} style={{ color: '#0041c2' }} icon={<LogoutOutlined />}>
          Log Out
        </Menu.Item>
      </Menu>
    </div>
  );
}

export default Sidebar;
