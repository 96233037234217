import React, {useState} from 'react'
import {ChromePicker, ColorResult} from 'react-color'


export const ColorInput: React.FC<{color?: string; onChange?: (hex: string) => void}> = (props) => {
  const [color, setColor] = useState(props.color || '')
  const [displayColorPicker, setDisplayColorPicker] = useState(false)

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  }
  
  const handleClose = () => {
    setDisplayColorPicker(false)
  }

  const handleChange = (color: ColorResult) => {
    setColor(color.hex)
    if (props.onChange) {
      props.onChange(color.hex)
    }
  }

  return (
    <div style={{width: '100%'}}>
      <div
        style={{
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
          width: '100%'
        }}
        onClick={handleClick}
      >
        <div
          style={{
            width: '100%',
            height: '14px',
            borderRadius: '2px',
            background: color,
          }}
        />
      </div>
      {
        displayColorPicker
          ? (
            <div
              style={{
                position: 'absolute',
                zIndex: 2,
              }}
            >
              <div
                style={{
                  position: 'fixed',
                  top: '0px',
                  right: '0px',
                  bottom: '0px',
                  left: '0px',
                }}
                onClick={handleClose}
              />
              <ChromePicker color={color} onChange={handleChange} />
            </div>
          )
          : null
      }
    </div>
  )
}
