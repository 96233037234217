import React, { FunctionComponent } from 'react';
import './index.css';

export const Help: FunctionComponent = (props): any => {
    return (
      <iframe
      title='Analytics'
      width={"100%"}
      className='analytics-iframe'
      src='https://share.hsforms.com/1g3qq9GBGQwC-s8tI2NxUjA3acav'
      frameBorder="0"
      key={0}
    />
    )
}

export default Help;
