import { Layout, message, Row, Typography, Input, Button } from 'antd'
import { IIntegration } from "../../types";
import React, { useEffect, useState } from "react";
import {LoadingScreen } from '../../components/Loader/Loader'
import {SPONSORED_PRODUCTS_BASE_URL as APP_BASE_URL} from '../../constants'
import {useAuthContext} from '../../auth/AuthProvider'

const BoostItems: React.FC<{ integration: IIntegration }> = (props) => {
  const [boostItemsInput, setBoostItemsInput] = useState(`[]`);
  const [filterItemsInput, setFilterItemsInput] = useState(`[]`);
  const [boostItemRes, setBoostItems] = useState<any[]>([]);
  const [filterItemRes, setFilterItems] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);
  const { authenticatedFetch } = useAuthContext()

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await authenticatedFetch(
          APP_BASE_URL + `api/itemlist/`,
        );

        const boostItemsData = response.data.filter((item: any) => item.associated_integration === props.integration._id && item.type === 'boost');
        setBoostItems(boostItemsData);
        if (boostItemsData.length > 0) {
          setBoostItemsInput(JSON.stringify(boostItemsData[0].items || []));
        } else {
          setBoostItemsInput('[]');
        }

        const filterItemsData = response.data.filter((item: any) => item.associated_integration === props.integration._id && item.type === 'filter');
        setFilterItems(filterItemsData);
        if (filterItemsData.length > 0) {
          setFilterItemsInput(JSON.stringify(filterItemsData[0].items || []));
        } else {
          setFilterItemsInput('[]');
        }
        setLoading(false);
      } catch (error) {
        message.error('Failed to fetch items');
      }
    };

    fetchItems();
  }, [props.integration._id, authenticatedFetch]);

  const isValidArray = (input: any) => {
    try {
      const parsed = JSON.parse(input);
      return Array.isArray(parsed);
    } catch (error) {
      return false;
    }
  };

  const handleBoostItemsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBoostItemsInput(e.target.value);
  };

  const handleFilterItemsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFilterItemsInput(e.target.value);
  };

  const handlePostItems = async () => {
    if (!isValidArray(boostItemsInput) || !isValidArray(filterItemsInput)) {
      message.error('Boost Items or Filter Items are invalid');
      return;
    }
    
  const boostItems = JSON.parse(boostItemsInput);
  const filterItems = JSON.parse(filterItemsInput);
  
  try {
    
    const boostMethod = boostItemRes && boostItemRes.length > 0 ? 'PATCH' : 'POST';
    const filterMethod = filterItemRes && filterItemRes.length > 0 ? 'PATCH' : 'POST';
    let boost_id = '';
    let filter_id = '';
    if (boostItemRes && boostItemRes.length > 0) {
      boost_id = boostItemRes[0]._id;
    }
    if (filterItemRes && filterItemRes.length > 0) {
      filter_id = filterItemRes[0]._id;
    }
    const response = await authenticatedFetch(
      `${APP_BASE_URL}api/itemlist/${boost_id}`,
      boostMethod,
      {
        type: 'boost',
        items: boostItems,
        associated_integration: props.integration._id,
      },
    );

    if (!response) {
      throw new Error('Could not update boost items, please try again later or contact our team'); 
    }

    const filterResponse = await authenticatedFetch(
      `${APP_BASE_URL}api/itemlist/${filter_id}`,
      filterMethod,
      {
        type: 'filter',
        items: filterItems,
        associated_integration: props.integration._id,
      },
    );

    if (!filterResponse) {
      throw new Error('Could not update filter items, please try again later or contact our team');
    }

    message.success('Items were updated successfully');
  } catch (error) {
      message.error(`Failed to update items: ${error.message}`);
    }
  };

  const validateInputs = () => {
    isValidArray(boostItemsInput) ? message.success('Boost Items are valid') : message.error('Boost Items should be an array of strings, example : ["itemid1","itemid2","itemid3"]');
    isValidArray(filterItemsInput) ? message.success('Filter Items are valid') : message.error('Filter Items should be an array of strings, example : ["itemid1","itemid2","itemid3"]');
  };

  return (
    isLoading? (<LoadingScreen/>) : (<Layout.Content style={{ width: '100%' }}>
      <Typography.Title level={3}>Manage Boost Items</Typography.Title>
      <Input.TextArea
        value={boostItemsInput}
        onChange={handleBoostItemsChange}
        rows={4}
        placeholder='Your item ids here ex:["4D81-0066","0L28-4791","9J39-3012" ...]'
      />
  
      <Typography.Title level={3}>Manage Filter Items</Typography.Title>
      <Input.TextArea
        value={filterItemsInput}
        onChange={handleFilterItemsChange}
        rows={4}
        placeholder='Your item ids here ex:["4D81-0066","0L28-4791","9J39-3012" ...]'
      />
  
  <Button onClick={validateInputs} type="primary" style={{ marginTop:'10px',marginRight: '10px', width: '100px', height: '40px' }}>Validate</Button>
  <Button onClick={handlePostItems} type="primary" style={{marginTop:'10px', marginRight: '10px', width: '100px', height: '40px' }}>Save</Button>
    </Layout.Content>)
  );
};

export default BoostItems;