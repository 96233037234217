import React, { FunctionComponent, useEffect, useState } from 'react';
//import './index.css';

import { useLocation } from 'react-router-dom';
import { IIntegration } from '../../types';
import { Avatar, Col, Divider, Layout, Menu, Row, Select, Typography } from 'antd';
import { Link } from 'react-router-dom';

interface HeaderProps {
  handleChange: (any: any) => void,

  integrations: IIntegration[]
  loading: boolean
}


export const Header: FunctionComponent<HeaderProps> = (props) => {

  const location = useLocation();

  const [integration, setIntegration] = useState('')
  const [path, setPath] = useState('')

  const handleChange = (e: any) => {
    setIntegration(e)
    props.handleChange(e)
  }

  // useEffect(() => {
  //   setPath(location.pathname)
  // }, [location]);

  useEffect(() => {
    if (!props.loading && props.integrations.length > 0) {
      setIntegration(props.integrations[0]._id)
    }
  }, [props.loading]);


  return (
    <Layout.Header style={{ width: '100%', background: '#fff', padding: '0 1% 0 1%' }}>
      <Col>
        <Row>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ height: '1rem' }}>
            <Row>
              <Link
                to="/"
              >
                <div className="logo">
                  <Col>
                    <Row>
                      <Col>
                        <Row style={{ marginTop: '50%' }}>
                          <Avatar
                            alt="JewelML"
                            size={32}
                            src="https://appclient-dev.jewelml.io/favicon.png"
                          />
                        </Row>
                      </Col>
                      <Col>
                        <Divider type="vertical" style={{ borderRight: '1px solid black', height: '50%' }} />
                      </Col>
                      <Col>
                        <Row style={{ marginTop: '10%' }}>
                          <Typography.Title level={3} style={{ fontFamily: 'Nexa' }}>
                            Jewel ML Portal
                          </Typography.Title>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </Link>
            </Row>
          </Col>
          <Col xs={16} sm={16} md={16} lg={16} xl={16}>
            <Row justify="end" style={{ height: '1rem' }}>
              <Col>
                <Row>
                  <Col>
                    <Row style={{ marginTop: '5%' }}>
                      {
                        props.loading
                          ? (
                            <Select
                              placeholder="Loading..."
                              size='large'
                              style={
                                {
                                  width: 240
                                }
                              }
                              loading
                            >
                            </Select>
                          )
                          : (
                            <Select
                              onChange={handleChange}
                              value={integration}
                              size='large'
                              style={
                                {
                                  width: 240
                                }
                              }
                            >
                              {
                                Object.entries((props.integrations || [])).map((key) => {
                                  return (
                                    <Select.Option key={key[0]} value={key[1]._id}>{key[1].title}</Select.Option>
                                  )
                                })
                              }
                            </Select>
                          )
                      }
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Layout.Header>
  );
}
