import React, { useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";

import Overview from './screens/Overview';
import Settings from './screens/Carrosel/Settings';

import { Layout as AntdLayout } from 'antd'

import { useAuthContext } from './auth/AuthProvider'

import Analytics from './screens/Carrosel/Analytics';
import Sidebar from './components/sidebar';
import { Header } from './components/header';

import './Layout.css'

import { LoadingScreen } from './components/Loader/Loader';
import { IIntegration, IOrganization } from './types';
import { SPONSORED_PRODUCTS_BASE_URL as APP_BASE_URL } from './constants';
import { message } from 'antd';
import Help from './screens/Help';
import Search from './screens/Search';
import Banners from './screens/Banners';
import CatalogItens from './screens/Catalog_Itens';
import BoostItems from './screens/Boost_Filter';
import { UserManager } from './screens/User_Manager';
import OrganizationUserManagement from './screens/OrganizationUserManagement/OrganizationUserManagement'
import { Design } from './Design/Design';
import OnlineSalesReport from './screens/Online_Sales_Report';

export const Layout = () => {

  const htmlRef = React.useRef(null)

  const { user, authenticatedFetch, isAuthenticated, logout } = useAuthContext()
  const [integrationId, setIntegrationId] = useState("");
  const [integration, setIntegration] = useState<IIntegration>();
  const [organizations, setOrganizations] = useState<IOrganization[]>([])
  const [integrations, setIntegrations] = useState<IIntegration[]>([])
  const [isLoadingIntegrations, setIsLoadingIntegrations] = useState(false)

  const [sidebarCollapsed, setSidebarCollaped] = React.useState(false)

  const SidebarProps = {
    collapsed: sidebarCollapsed,
    setCollapsed: setSidebarCollaped,
    logout,
  }

  useEffect(() => {
    setIsLoadingIntegrations(true)
      ; (async () => {
        try {
          //console.log("Loading User from DB.")
          if (user!.user_metadata) {
            // const response = await authenticatedFetch<IOrganization[]>(APP_BASE_URL + `api/organization/all/associated_users/?user=${user!.user_metadata['jewel_user_id']}&populate=associated_integrations&populate=associated_users`)
            const response = await authenticatedFetch<IOrganization[]>(APP_BASE_URL + `portal/user/organizations`)

            const organizations: IOrganization[] = response.data
            const integrations = organizations.map(org => org.associated_integrations!).flat(1).filter(int => int.active === true)

            setOrganizations(organizations)
            setIntegrations(integrations)
            setIntegration(integrations[0])
            setIntegrationId(integrations[0]._id)
          }
        } catch (err: any) {
          message.error(`failed to load integrations: ${err.message}`)
        }
        setIsLoadingIntegrations(false)
      })()
  }, [])

  const handleChange = (e: any) => {
    //console.log('click', e);
    setIntegrationId(e)
    const integrationSelected = integrations.filter(integration => integration._id == e)
    setIntegration(integrationSelected[0])
  }

  if (!isAuthenticated) {
    return <LoadingScreen />
  }

  return (

    <AntdLayout className="layout">
      <div ref={htmlRef} />
      <Header handleChange={handleChange} integrations={integrations} loading={isLoadingIntegrations} />
      <AntdLayout>
        <AntdLayout.Sider width={200} collapsible collapsed={sidebarCollapsed} onCollapse={(value) => setSidebarCollaped(value)}>
          <Sidebar logout={logout} isAuthenticated={isAuthenticated} key={0} integration={integration} />
        </AntdLayout.Sider>
        <AntdLayout
          style={{
            padding: '16px',
          }}
        >
          <AntdLayout.Content className="content">
            {
              integration &&
              <Routes>
                <Route path="/analytics/audience" element={<Analytics integration={integration} report="audience"/>} />
                <Route path="/analytics/performance" element={<Analytics integration={integration} report="performance"/>} />
                {integration._id == '65fc5d6a9a541513bafa57cd' && <Route path="/salesreport" element={<OnlineSalesReport integration={integration}/>} />}
                <Route path="/carousel/settings" element={<Settings integration={integration} />} />
                <Route path="/carousel/design" element={<Design integration={integration} />} />
                <Route path="/catalog/settings" element={<CatalogItens integration={integration} />} />
                <Route path="/catalog/BoostAndFilter" element={<BoostItems integration={integration}/>} />
                {
                  integration
                    ? (
                      <Route path="/organization/users" element={<OrganizationUserManagement organization_id={integration.associated_organization}/>} />
                    )
                    : null
                }
                <Route path="/help" element={<Help />} />
                <Route path="*" element={<Overview integration={integration} />} />
                {/* <Route path="*" element={<Navigate to="/overview" replace />} /> */}
                {/* <Route path="/search" element={<Search />} />
                <Route path="/banners" element={<Banners />} /> */}
                {/* <Route path="/UserManager" element={<UserManager organization_id={integration?.associated_organization} />} /> */}
              </Routes>
            }
          </AntdLayout.Content>
          <AntdLayout.Footer
              style={{
                textAlign: 'center',
              }}
            >
          Copyright © 2022 Jewel. All rights reserved.
        </AntdLayout.Footer>
        </AntdLayout>
      </AntdLayout>
    </AntdLayout>
  );

}
