import React, { FunctionComponent } from 'react'
import {Row, Col, Layout} from 'antd'
import {LoadingOutlined} from '@ant-design/icons'

import './Loading.css'

export enum LoadingSize {
  xsmall = 'xsmall',
  small = 'small',
  medium = 'medium',
}

export interface LoadingProps {
  size?: string,
}

export const Loading: FunctionComponent<LoadingProps> = (props) => {
  return !props.size ? <LoadingOutlined className='medium'/> : <LoadingOutlined className={props.size} />
}

export const LoadingScreen = () => {
  return (
    <div>
      <Layout>
        <Row align="top" justify="center">
          <Col
            span={24}
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              height: '100vh',
              justifyContent: 'center',
            }}
          >
            <Loading />
          </Col>
        </Row>
      </Layout>
    </div>
  )
}