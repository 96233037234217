import { Button, Result } from 'antd';
import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingScreen } from '../../../components/Loader/Loader';
import { IIntegration } from '../../../types';
import './index.css';

interface AnalyticsProps {
  integration?: IIntegration,
  report?: string
}

function Analytics(props: AnalyticsProps) {

  const [isLoading, setLoading] = useState(true);
  const [integration, setIntegration] = useState<IIntegration>()
  const [report, setReport] = useState<string>()

  useEffect(() => {

    if((props.integration && props.integration._id !== integration?._id) || (props.report && props.report !== report)){

      
      setLoading(true);

      setIntegration(props.integration)
      setReport(props.report)

      setLoading(false)

    }
  }, [props.integration, props.report]);

  const getIframe = (source:string, index:number) => {
    return (
      <iframe 
        title='Analytics'
        width={"100%"}
        className='analytics-iframe'
        src={source}
        frameBorder="0" 
        key={index}
        allowFullScreen
        onLoad={() => {
          setLoading(false);
        }}
      />
    )
  }

  let navigate = useNavigate();

  const loadReportByName = (report?:string) => {

    if(report && report === "performance") {

      if(integration?.integration_properties?.gds_url!) {
        return getIframe(integration?.integration_properties?.gds_url!, 0)
      } else {
        return (
          <Result
          status="404"
          title="Not ready.. yet!"
          subTitle="Your personalized Performance page will be available soon!"
          extra={<Button type="primary" onClick={() => navigate('/help')}>Need help?</Button>}
          />
        )
      }
    } else if(report && report === "audience") {

      if(integration?.integration_properties?.aud_url!) {
        return getIframe(integration?.integration_properties?.aud_url!, 0)
      } else {
        return (
        <Result
          status="404"
          title="Not ready.. yet!"
          subTitle="Your personalized Audience page will be available soon!"
          extra={<Button type="primary" onClick={() => navigate('/help')}>Need help?</Button>}
        />
        )
      }
    }

  }

  //https://ant.design/components/result/
  return (
    <div>
      {isLoading ? <LoadingScreen /> : null}
      { loadReportByName(report) }      
    </div>
  );
}

export default Analytics;
