import { Button, Result } from 'antd';
import React, { useEffect, useState } from 'react';
import { LoadingScreen } from '../../components/Loader/Loader';
import { IIntegration } from '../../types';
import { useNavigate } from "react-router-dom";

import './index.css';

interface OnlineSalesReportProps {
  integration? : IIntegration,
}

function OnlineSalesReport(props: OnlineSalesReportProps) {
  
  const [isLoading, setLoading] = useState(true);
  const [integration, setIntegration] = useState<IIntegration>();
  const [iframeKey, setIframeKey] = useState(0);

  useEffect(() => {
    setLoading(true);

    if (props.integration) {
      setIntegration(props.integration);
    }
    setLoading(false);
  }, [props.integration]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIframeKey(prevKey => prevKey + 1); 
    }, 300000); 
    return () => clearInterval(interval); 
  }, []);

  const getIframe = (source: string, index: number) => {
    return (
      <iframe
        title='Analytics'
        width={"100%"}
        className='analytics-iframe'
        src={source}
        frameBorder="0"
        key={`${index}-${iframeKey}`}
        onLoad={() => {
          setLoading(false);
        }}
      />
    )
  }

  let navigate = useNavigate();

  return (
    <div>
      {isLoading ? <LoadingScreen /> : null}
      {(integration && integration?.integration_properties?.sr_url!) ?
        getIframe(integration?.integration_properties?.sr_url!, 0) : 
        <Result
        status="404"
        title="Not ready.. yet!"
        subTitle="Your personalized online sales report page will be available soon!"
        extra={<Button type="primary" onClick={() => navigate('/help')}>Need help?</Button>}
      />}
    </div>
  );
}

export default OnlineSalesReport;