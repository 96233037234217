// import dotenv from 'dotenv'

// dotenv.config()

console.log("ENV: ", process.env)

process.env.SPONSORED_PRODUCTS_BASE_URL = process.env.SPONSORED_PRODUCTS_BASE_URL || 'https://portal-api.jewelml.io/'


export const SPONSORED_PRODUCTS_BASE_URL = process.env.SPONSORED_PRODUCTS_BASE_URL || '/'
