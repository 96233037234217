import React from 'react'
import qs from 'qs'
import createAuth0Client, {Auth0Client, Auth0ClientOptions, IdToken, User} from '@auth0/auth0-spa-js'
import {useLocation, Navigate} from 'react-router-dom'

import {AuthConfig, IUser} from '../types'
import {Fetch, makeAuthenticatedFetch} from '../utils/fetch'

import { SPONSORED_PRODUCTS_BASE_URL as APP_BASE_URL } from '../constants';

export interface AuthProviderState {
  hasError?: boolean
  error?: any
  loading: boolean
  isAuthenticated: boolean
  user?: User | undefined
  claims?: IdToken | undefined
  expires?: number | undefined
  accessToken?: string | undefined
  authenticatedFetch: Fetch
  logout: Auth0Client['logout']
}

const initialState: AuthProviderState = {
  isAuthenticated: false,
  loading: false,
  logout: () => undefined,
  authenticatedFetch: makeAuthenticatedFetch(null),
}

const AuthContext = React.createContext(initialState)
export const useAuthContext = () => React.useContext(AuthContext)

export const AuthProvider: React.FunctionComponent<{config: AuthConfig}> = (props) => {
  const [authState, setAuthState] = React.useState<AuthProviderState>(initialState)
  const [loggedIn, setLoggedIn] = React.useState(false);
  const {domain, clientId} = props.config
  const location = useLocation()
  const redirectPath = React.useRef('/')

  const auth0ClientOptions: Auth0ClientOptions = {
    cacheLocation: 'localstorage',
    client_id: clientId,
    domain,
    redirect_uri: window && window.location ? window.location.origin : undefined
  }

  React.useEffect(() => {

    ;(async () => {
      const auth0Client = await createAuth0Client(auth0ClientOptions)
      const searchParams = qs.parse(location.search, {ignoreQueryPrefix: true})

      //console.log("AuthProvider : "+JSON.stringify(searchParams))
      if (searchParams.code && searchParams.state) {
        setAuthState(prev => ({...prev, loading: true}))
        const loginResponse = await auth0Client.handleRedirectCallback()
        redirectPath.current = loginResponse.appState.windowLocationPath;

        //console.log("AuthProvider redirectPath.current : "+redirectPath.current)
      }

      const isAuthenticated = await auth0Client.isAuthenticated();
      //console.log(isAuthenticated);
      //console.log("AuthProvider isAuthenticated : "+isAuthenticated)
      if (!isAuthenticated) {
        await auth0Client.loginWithRedirect({
          appState: {
            originalUrl: window.location.href,
            windowLocationPath: window.location.pathname,
            windowLocationSearch: window.location.search,
          },
        })
      } else {
        const claims = await auth0Client.getIdTokenClaims()
        const authenticatedFetch = makeAuthenticatedFetch(claims.__raw)

        let auth0User = await auth0Client.getUser(claims.sub)

        try {

          //console.log("filling up Auth0 user with jewel user id")

          auth0User!.user_metadata = {}
          const user_response = await authenticatedFetch<IUser[]>(APP_BASE_URL + 'api/user/email/' + auth0User?.email)
          auth0User!.user_metadata['jewel_user_id'] = user_response.status == 200 ? user_response.data[0]._id : ''
          //console.log(auth0User)
          
        } catch(e) {

          //console.log("error retrieving logged user from jewel platform. Skipping..")

        }
        
        setAuthState({
          isAuthenticated,
          loading: false,
          logout: auth0Client.logout.bind(auth0Client, {
            returnTo: auth0ClientOptions.redirect_uri,
            localOnly: Boolean(auth0ClientOptions.redirect_uri && auth0ClientOptions.redirect_uri.indexOf('localhost') !== -1),
          }),
          accessToken: claims._raw,
          expires: claims.exp,
          user: auth0User,
          claims,
          authenticatedFetch,
        })
      }

      setLoggedIn(isAuthenticated);
    })()
  }, [domain, clientId, location.search, location.pathname])

  if (!authState.isAuthenticated) {
    return null
  }

  return (
    <div>
      <AuthContext.Provider value={authState}>{props.children}</AuthContext.Provider>
      {/* {loggedIn &&  <Navigate to='/' />} */}
      </div>
  )
}
