import {Role} from 'auth0'


export enum Auth0Roles {
    Editor = 'editor',
    Viewer = 'viewer',
}

export const hasAuth0Access = (roles: Role[], accessLevel: Auth0Roles): boolean => {
    return Boolean(roles.find(role => role.name === accessLevel))
}