import React, {FunctionComponent} from 'react'
import {BrowserRouter} from 'react-router-dom'

import {AuthConfig} from '../types'
import {AuthProvider} from './AuthProvider'

export interface AuthenticatedRouterProps {
  config: AuthConfig
}

export const AuthenticatedRouter: FunctionComponent<AuthenticatedRouterProps> = (props) => {
  return (
    <BrowserRouter>
      <AuthProvider config={props.config}>{props.children}</AuthProvider>
    </BrowserRouter>
  )
}